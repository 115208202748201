<template>
    <div>
        <div class="winex-wine-detail-wrap">
            <div class="winex-wine-detail-top-info-wrap">
                <div class="winex-wine-image-wrap" :style="{backgroundImage:`url(${wineInfo.image})`}">

                </div>
                <div class="winex-wine-info-wrap flex flex-col gap-[20px]">
                    <!-- 북마크 아이콘 -->
                    <!-- TODO :: 20220720 임시 주석 처리 -->
                    <!--<div class="bookmark-icon" @click="gettingReady"></div>-->

                    <!-- 와인 기본 정보 -->
                    <div class="default-wine-info-wrap">
                        <!--<h5 class="wine-brand">{{wineInfo.brand}}</h5>-->
                        <h4 class="wine-title">{{wineInfo.name}} </h4>
                    </div>

                    <!-- 와인 특성 정보 -->


                  <div class="flex">
                    <div class="flex flex-wrap gap-x-[20px] gap-y-[4px] mobile:gap-x-[0]">
                      <WineInfo title="Type" :subCategory="getSubCategoryInfo(1)" />
                      <WineInfo title="Grape" :subCategory="getSubCategoryInfo(18)" />
                      <WineInfo title="Format" :subCategory="getSubCategoryInfo(3)" />
                      <WineInfo title="Case*Bottle" :subCategory="getSubCategoryInfo(17)" />
                      <WineInfo title="Winery" :subCategory="getSubCategoryInfo(16)" />
                      <WineInfo title="Country" :subCategory="getSubCategoryInfo(2)" />
                      <WineInfo title="Region" :subCategory="getSubCategoryInfo(19)" />
                      <WineInfo title="SubRegion1" :subCategory="getSubCategoryInfo(20)" />
                      <WineInfo title="SubRegion2" :subCategory="getSubCategoryInfo(21)" />
                      <WineInfo title="SubRegion3" :subCategory="getSubCategoryInfo(22)" />
                      <WineInfo title="Grade" :subCategory="getSubCategoryInfo(4)" />
                      <WineInfo title="Vineyard" :subCategory="getSubCategoryInfo(23)" />
                      <WineInfo title="Vintage" :subCategory="getSubCategoryInfo(24)" />
                      <WineInfo title="Winex Minting Vintage(WMV)" :subCategory="getSubCategoryInfo(25)" />
                      <WineInfo title="Minter" :subCategory="{name: wineInfo.propertyList.minter}" />
                    </div>
                  </div>


                    <div class="detail-wine-info-wrap" v-if="false" >
                        <div class="detail-wine-info-title-wrap" v-if="$isMobile()" @click="toggleCharacter">
                            <div>특성</div>
                            <div class="on-off-btn" :class="{off:wineCharacteristicOpen}"></div>
                        </div>
                        <VueSlideToggle class="detail-wine-info-list" :open="wineCharacteristicOpen" :duration="$isMobile()?200:0">

                            <!--<div class="detail-wine-info-item" v-for="(property, key) in wineInfo.propertyList" v-bind:key="key">
                                <span class="detail-wine-info-title">{{changePropertyFirstUpper(key)}}</span>
                                <span class="detail-wine-info-content">{{property?property:"-"}}</span>
                            </div>-->

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Type</span>
                                <span class="detail-wine-info-content" style="font-weight: bold;">{{wineInfo.propertyList.type?wineInfo.propertyList.type:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Grape</span>
                                <span class="detail-wine-info-content" style="font-weight: bold;">{{wineInfo.propertyList.grape?wineInfo.propertyList.grape:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Winery</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.winery?wineInfo.propertyList.winery:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Format</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.format?wineInfo.propertyList.format:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Case * Bottle</span>
                                <span class="detail-wine-info-content">{{wineInfo["propertyList"]["case * bottle"]?wineInfo["propertyList"]["case * bottle"]:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Country</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.country?wineInfo.propertyList.country:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Region</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.region?wineInfo.propertyList.region:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">SubRegion1</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.subregion_1?wineInfo.propertyList.subregion_1:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">SubRegion2</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.subregion_2?wineInfo.propertyList.subregion_2:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">SubRegion3</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.subregion_3?wineInfo.propertyList.subregion_3:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Grade</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.grade?wineInfo.propertyList.grade:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Vineyard</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.vineyard?wineInfo.propertyList.vineyard:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Vintage</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.vintage?wineInfo.propertyList.vintage:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">Minter</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.minter?wineInfo.propertyList.minter:"-"}}</span>
                            </li>

                            <li class="detail-wine-info-item">
                                <span class="detail-wine-info-title">WMV</span>
                                <span class="detail-wine-info-content">{{wineInfo.propertyList.wmv?wineInfo.propertyList.wmv:"-"}}</span>
                            </li>

                        </VueSlideToggle>
                    </div>

                    <div class="nft-link-info-wrap m-0">
                        <div class="nft-link-col">
                            <div class="nft-link-title">Token ID</div>
                            <div class="nft-link-content"><a :href="`https://polygonscan.com/token/${$route.params.address}?a=${$route.params.tokenId}`" target="_blank">{{$route.params.tokenId}}</a></div>
                        </div>
                        <div class="nft-link-col">
                            <div class="nft-link-title">Contract Address</div>
                            <div class="nft-link-content"><a :href="`https://polygonscan.com/token/${$route.params.address}`" target="_blank">{{$route.params.address}}</a></div>
                        </div>
                    </div>

                    <!-- NFT 정보 -->
                    <div class="nft-wine-info-wrap mb-[40px]">
                        <div class="nft-wine-info-table">
                            <div class="nft-wine-info-title-wrap">
                                <div class="nft-wine-info-title">소유자</div>
                                <div class="nft-wine-info-title">Sell Price</div>
                                <div class="nft-wine-info-title">Highest Offer</div>
                            </div>
                            <div class="nft-wine-info-content-wrap">
                                <div class="nft-wine-info-content">
                                    <span class="coin-price" style="word-break: break-all">{{wineInfo.owner}}</span>
                                </div>
                                <div class="nft-wine-info-content">
                                    <span class="coin-price">
                                        <span v-if="this.wineInfo.status === 1">
                                            {{$numberFormat(wineInfo.price.coin)}}
                                        </span>
                                    </span>
                                    <span v-if="this.wineInfo.status === 1" class="won-price">
                                        ({{$numberFormat(wineInfo.price.won).toString().split('.')[0]}}원)
                                    </span>
                                </div>
                                <div class="nft-wine-info-content">
                                    <span class="coin-price">
                                        <span v-if="this.wineInfo.status !== 3">
                                            {{$numberFormat(wineInfo.lastOffer.coin)}}
                                        </span>
                                    </span>
                                    <span v-if="this.wineInfo.status !== 3" class="won-price">
                                        ({{$numberFormat(wineInfo.lastOffer.won).toString().split('.')[0]}}원)
                                    </span>
                                </div>
                            </div>
                        </div>
                        <span>· 1분전 환율, 와넥스 이용내역 기준입니다.</span>
                    </div>
                    <div v-if="!this.isNftSeller" class="wine-btn-wrap" >
                        <!-- 구매자 -->
                        <DefaultButton
                            :content="'Make Offer'"
                            :disabled="this.wineInfo.status === 3"
                            :class-list="'wine-detail-btn make-offer-btn'"
                            :on-click="openSuggestModal" />
                        <DefaultButton
                            :content="'Buy Now'"
                            :disabled="this.wineInfo.status !== 1"
                            :class-list="'wine-detail-btn buy-now-btn'"
                            :on-click="openBidModal" />
                    </div>
                    <div v-else class="wine-btn-wrap" >
                        <!-- 판매자 -->
                        <DefaultButton v-if="this.wineInfo.status === 1"
                            :content="'Cancel'"
                            :class-list="'wine-detail-btn make-offer-btn'"
                            :on-click="openCancelModal" />
                        <DefaultButton v-if="this.wineInfo.status === 2"
                            :content="'SELL'"
                            :class-list="'wine-detail-btn make-offer-btn'"
                            :on-click="openSellModel" />
                        <DefaultButton v-if="this.wineInfo.status === 2"
                            :content="'REDEEM'"
                            :class-list="'wine-detail-btn buy-now-btn'"
                            :on-click="moveRedeemPage" />
                    </div>
                </div>
            </div>

            <div class="wine-info-wrap">
                <div class="wine-info-item">
                    <h3 class="wine-detail-title">Description</h3>
                    <div class="wine-description" v-html="wineInfo.description">

                    </div>
                </div>
                <!--Offer List 표시부 -->
                 <div class="wine-info-item" v-if="this.wineInfo.status !== 3">
                    <h3 class="wine-detail-title">Offer List</h3>
                    <div class="item-activity-wrap">
                        <table class="winex-table">
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Price(USDC)</th>
                                    <th>Price(WON)</th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody v-for= "item in this.offerList" :key="item.key">
                                <tr>
                                    <td>{{item.buyer}}</td>
                                    <td>
                                        <div class="double-line">
                                            <span class="main-data usdc-data"><span>{{$numberFormat(item.coin)}}</span></span>
                                        </div>
                                    </td>
                                    <td>{{$numberFormat(item.won).toString().split('.')[0]}}</td>
                                    <td v-if= "item.enableAccept == true">
                                         <DefaultButton
                                            :content="'Accept'"
                                            :on-click="() => acceptNftOffer(item)"
                                            :class-list="'outline-white-lld-btn w120 h40'"
                                        ></DefaultButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- -->
                <div v-if ="false">
                    <div class="wine-info-item">
                        <h3 class="wine-detail-title">Item Activity</h3>
                        <div class="item-activity-wrap">
                            <table class="winex-table">
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Date</th>
                                        <th>Price</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>TXID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- 여기 반복-->
                                    <tr>
                                        <td><span class="event-tag activity-sale">Sale</span></td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data">2022.01.13</span>
                                                <span class="sub-data">(19:52:12)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data usdc-data"><span>5,500</span></span>
                                                <span class="sub-data">(3,000,000원)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <DefaultButton
                                                :content="'View Detail'"
                                                :class-list="'outline-white-lld-btn w120 h40'"
                                            ></DefaultButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span class="event-tag activity-transfer">Transfer</span></td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data">2022.01.13</span>
                                                <span class="sub-data">(19:52:12)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data usdc-data"><span>5,500</span></span>
                                                <span class="sub-data">(3,000,000원)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <DefaultButton
                                                    :content="'View Detail'"
                                                    :class-list="'outline-white-lld-btn w120 h40'"
                                            ></DefaultButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span class="event-tag activity-list">List</span></td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data">2022.01.13</span>
                                                <span class="sub-data">(19:52:12)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data usdc-data"><span>5,500</span></span>
                                                <span class="sub-data">(3,000,000원)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <DefaultButton
                                                    :content="'View Detail'"
                                                    :class-list="'outline-white-lld-btn w120 h40'"
                                            ></DefaultButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span class="event-tag activity-offer">Offer</span></td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data">2022.01.13</span>
                                                <span class="sub-data">(19:52:12)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="double-line">
                                                <span class="main-data usdc-data"><span>5,500</span></span>
                                                <span class="sub-data">(3,000,000원)</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <span class="append-link">0x08x08e09x456x154fsd</span>
                                        </td>
                                        <td>
                                            <DefaultButton
                                                    :content="'View Detail'"
                                                    :class-list="'outline-white-lld-btn w120 h40'"
                                            ></DefaultButton>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <AlertModal
                :is-open="bidModalOpenCheck"
                :use-custom="true"
        >
            <template v-slot:custom>
                <div class="detail-modal-wrap bid-modal">
<!--                    <h2 class="detail-modal-title">즉시 입찰을 하시겠습니까?</h2>-->
                    <div class="detail-modal-content-wrap">
                        <!--<p class="wine-brand">{{wineInfo.brand}}</p>-->
                        <h3 class="wine-name">{{wineInfo.name}}</h3>
                    </div>
                    <div class="detail-modal-count-info-wrap">
                        <!--<div class="detail-modal-count-info-row">
                            <div class="detail-modal-count-info-title">잔여 수량</div>
                            <div class="detail-modal-count-info-content"><span class="bold">{{remainingQuantity}}</span>/ {{totalQuantity}}</div>
                        </div>-->
                        <div class="detail-modal-count-info-row">
                            <div class="detail-modal-count-info-title">판매 가격</div>
                            <div class="detail-modal-count-info-content">
                                <div class="price-info-wrap">
                                    <span class="coin-price">{{wineInfo.price.coin}}</span><span class="won-price">({{$numberFormat(wineInfo.price.won).toString().split('.')[0]}}원)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buy-info-wrap">
                        <div class="buy-info-row">
                            <div class="buy-info-title">구매 가격</div>
                            <div class="buy-info-content">
                                <div class="price-info-wrap">
                                    <span class="coin-price">{{sellCoinPrice}}</span><span class="won-price">({{$numberFormat(sellWonPrice).toString().split('.')[0]}}원)</span>
                                </div>
                            </div>
                        </div>
                        <!--<div class="buy-info-row">
                            <div class="buy-info-title">입찰 수량</div>
                            <div class="buy-info-content">
                                <div class="number-input-btn">
                                    <div class="number-btn minus-btn" @click="minusBidCount"></div>
                                    <input type="number" v-model="bidCount" />
                                    <div class="number-btn plus-btn" @click="plusBidCount"></div>
                                </div>

                            </div>
                        </div>-->
                    </div>
                    <div class="btn-wrap">
                        <DefaultButton
                                :content="'Close'"
                                :on-click="closeBidModal"
                                :class-list="'outline-white-lld-btn'"
                        >
                        </DefaultButton>
                        <DefaultButton
                                :content="'Buy Now'"
                                :on-click="submitNftBuy"
                                :class-list="'primary-btn'"
                        >
                        </DefaultButton>
                    </div>
                </div>
            </template>
        </AlertModal>
        <AlertModal
        :is-open="suggestModalOpenCheck"
        :use-custom="true">
            <template v-slot:custom>
                <div class="detail-modal-wrap suggest-modal">
                    <!--<h2 class="detail-modal-title">가격을 제시하시겠습니까?</h2>-->
                    <div class="detail-modal-content-wrap">
                        <!--<p class="wine-brand">{{wineInfo.brand}}</p>-->
                        <h3 class="wine-name">{{wineInfo.name}}</h3>
                    </div>
                    <div class="detail-modal-count-info-wrap">
                        <!--<div class="detail-modal-count-info-row">
                            <div class="detail-modal-count-info-title">잔여 수량</div>
                            <div class="detail-modal-count-info-content"><span class="bold">3</span>/ 12</div>
                        </div>-->
                        <div class="detail-modal-count-info-row">
                            <div class="detail-modal-count-info-title">판매 가격</div>
                            <div class="detail-modal-count-info-content">
                                <div class="price-info-wrap">
                                    <span class="coin-price">{{sellCoinPrice}}USDC</span><span class="won-price">({{$numberFormat(wineInfo.price.won).toString().split('.')[0]}}원)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buy-info-wrap">
                        <div class="buy-info-row select-date-row">
                            <div class="buy-info-title select-expire-date-title"><span>만료 기간</span></div>
                            <div class="buy-info-content">
                                <div class="select-expire-date">
                                    <DefaultDropDown :list-item="dropDownDateArray"
                                    :selectedData.sync="dropDownSelectDate"
                                    :list-height="40"></DefaultDropDown>
                                    <input type="time" class="select-time">
                                </div>
                            </div>
                        </div>
                        <!--<div class="buy-info-row">
                            <div class="buy-info-title">제시 수량</div>
                            <div class="buy-info-content">
                                <div class="number-input-btn">
                                    <div class="number-btn minus-btn" @click="minusSuggestCount"></div>
                                    <input type="number" v-model="suggestCount"/>
                                    <div class="number-btn plus-btn" @click="plusSuggestCount"></div>
                                </div>

                            </div>
                        </div>-->
                        <div class="buy-info-row">
                            <div class="buy-info-title">
                                <div class="buy-info-double-line suggest-price-title">
                                    <span>제시 가격</span>
                                    <span class="small-buy-title"></span>
                                </div>
                            </div>
                            <div class="buy-info-content">
                                <div class="price-info-wrap">
                                    <div class="buy-info-double-line" style="align-items:flex-end;">
                                        <input type="number" class="winex-input input-suggest-price" v-model="suggestPrice">
                                        <span class="exchange-price">({{$numberFormat(suggestPriceToWon).toString().split('.')[0]}}원)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap">
                        <DefaultButton
                                :content="'Close'"
                                :on-click="closeSuggestModal"
                                :class-list="'outline-white-lld-btn'"
                        >
                        </DefaultButton>
                        <DefaultButton
                                :content="'Make Offer'"
                                :on-click="submitNftOffer"
                                :class-list="'primary-btn'"
                        >
                        </DefaultButton>
                    </div>
                </div>
            </template>
        </AlertModal>
        <AlertModal
        :is-open="sellModelOpenCheck"
        :use-custom="true">
            <template v-slot:custom>
                <div class="detail-modal-wrap suggest-modal">
                    <!--<h2 class="detail-modal-title">판매 가격을 제시하시겠습니까?</h2>-->
                    <div class="detail-modal-content-wrap">
                        <!--<p class="wine-brand">{{wineInfo.brand}}</p>-->
                        <h3 class="wine-name">{{wineInfo.name}}</h3>
                    </div>
                    <div class="buy-info-wrap">
                        <div class="buy-info-row">
                            <div class="buy-info-title">
                                <div class="buy-info-double-line suggest-price-title">
                                    <span>판매 가격</span>
                                    <span class="small-buy-title">USDC</span>
                                </div>
                            </div>
                            <div class="buy-info-content">
                                <div class="price-info-wrap">
                                    <div class="buy-info-double-line" style="align-items:flex-end;">
                                        <input type="number" class="winex-input input-suggest-price" v-model="sellPrice">
                                        <span class="exchange-price">({{$numberFormat(sellPriceToWon).toString().split('.')[0]}}원)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap">
                        <DefaultButton
                                :content="'Close'"
                                :on-click="closeSellModal"
                                :class-list="'outline-white-lld-btn'"
                        >
                        </DefaultButton>
                        <DefaultButton
                                :content="'Sell'"
                                :on-click="submitNftSell"
                                :class-list="'primary-btn'"
                        >
                        </DefaultButton>
                    </div>
                </div>
            </template>
        </AlertModal>
        <AlertModal
        :is-open="cancelModelOpenCheck"
        :use-custom="true">
            <template v-slot:custom>
                <div class="detail-modal-wrap suggest-modal">
                    <!--<h2 class="detail-modal-title">NFT 판매를 취소하시겠습니까?</h2>-->
                    <div class="detail-modal-content-wrap">
                        <!--<p class="wine-brand">{{wineInfo.brand}}</p>-->
                        <h3 class="wine-name">{{wineInfo.name}}</h3>
                    </div>
                    <div class="buy-info-wrap">
                        <div class="buy-info-row">
                            <div class="buy-info-title">
                                <div class="buy-info-double-line suggest-price-title">
                                    <span>판매 가격</span>
                                    <span class="small-buy-title">USDC</span>
                                </div>
                            </div>
                            <div class="buy-info-content">
                                <div class="price-info-wrap">
                                    <div class="buy-info-double-line" style="align-items:flex-end;">
                                        <span class="exchange-price">{{$numberFormat(wineInfo.price.coin)}}</span>
                                        <span class="exchange-price">({{$numberFormat(wineInfo.price.won).toString().split('.')[0]}}원)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrap">
                        <DefaultButton
                                :content="'Close'"
                                :on-click="closeCancelModal"
                                :class-list="'outline-white-lld-btn'"
                        >
                        </DefaultButton>
                        <DefaultButton
                                :content="'Cancel'"
                                :on-click="submitNftSellCancel"
                                :class-list="'primary-btn'"
                        >
                        </DefaultButton>
                    </div>
                </div>
            </template>
        </AlertModal>
        <AlertModal
            :title="modal.title"
            :content="modal.content"
            :showWait="modal.showWait"
            :pcWidth="500"
            :button-content="'확인'"
            :button-class="'primary-btn'"
            :is-open="flagModalOnOff"
            :confirm-click-btn="closeModal">
        </AlertModal>
        <ConfirmModal
        :title="'죄송합니다.'"
        :content="'멤버쉽 홀더만 민팅 거래에 참여 가능합니다.'"
        :is-info="true"
        :cancel-button-content="'Close'"
        :cancel-click-btn="closeMembershipCheckModal"
        :button-content="'멤버쉽 혜택 알아보기'"
        :confirm-click-btn="moveMembershipPage"
        :is-open="checkMembershipModal"
        >

        </ConfirmModal>
    </div>
</template>
<script>
    import DefaultButton from "../../components/buttons/DefaultButton";
    import {VueSlideToggle} from 'vue-slide-toggle'
    import getContractApi from "../../api/contract";

    import AlertModal from "../../components/modals/AlertModal";
    import DefaultDropDown from "../../components/dropdowns/DefaultDropDown";
    import ConfirmModal from "../../components/modals/ConfirmModal";
    import {mapState} from "vuex";
    import WineInfo from "@/components/redeem/WineInfo";
    //import { isHexString } from "@ethersproject/bytes";
    export default {
        components: {WineInfo, ConfirmModal, DefaultDropDown, AlertModal, DefaultButton,VueSlideToggle},
        created() {
            this.contract = getContractApi();
            this.market = this.contract.getWineMarket();
            const address = this.$route.params.address;
            const tokenId = this.$route.params.tokenId;

            this.wineInfo.key = `${address}/${tokenId}`;
            this.tokenId = tokenId;

            this.checkMembershipModal = false
        },
        mounted() {
            this.refreshRate = 1000 * 5;
            // @TODO 로딩 시작
            this.update().finally(() => {
                // @TODO 로딩 끝
            });
            this.doUpdateActivities();
            this.updateActivities();

        },
        beforeDestroy() {
            this.refreshRate = 0;
            // 통신 이후 데이터 세팅
            this.wineInfo = {
                brand:'Cote de Nuits',
                name:'Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019',
                key:1,
                price: {
                    coin:1270,
                    won:5260000
                },
                description:"<div class=\"sc-1xf18x6-0 haVRLx item--description-text\"><div class=\"sc-edvquz-0 fWQpLM\"><p>Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019</p><p><a class=\"sc-1pie21o-0 elyzfO\" href=\"https://luolle.com/product/MusignyBlanc2019\" rel=\"nofollow noopener\" target=\"_blank\">https://luolle.com/product/MusignyBlanc2019</a></p></div></div>",
                image: "https://cdn.imweb.me/thumbnail/20220508/45ceb1e85eda6.png"
            }

        },
        data () {
            return  {
                contract: null,
                market: null,
                refreshRate: 15000,
                tokenId: 0,
                wineInfo: {
                    owner: "0x0",
                    price: { coin: 0, won: 0 },
                    lastOffer: { buyer: "0x0", coin: 0, won: 0 },
                    propertyList: {
                        type:"",
                        grape:"",
                        winery:"",
                        format:"",
                        "case * bottle":"",
                        country:"",
                        region:"",
                        subregion_1:"",
                        subregion_2:"",
                        subregion_3:"",
                        grade:"",
                        vineyard:"",
                        vintage:"",
                        //serial_number:"",
                        wmv:0
                    }
                },
                offerList: [],
                wineCharacteristicOpen: true,
                bidModalOpenCheck:false,
                suggestModalOpenCheck: false,
                sellModelOpenCheck:false,
                cancelModelOpenCheck: false,
                isNftSeller:false,
                checkMembershipModal:false,
                modal : {
                    title: "",
                    content: ""
                },
                dropDownDateArray:[
                    {
                        value:1,
                        text:"1일"
                    },
                    {
                        value:3,
                        text:"3일"
                    },
                    {
                        value:7,
                        text:"7일"
                    },
                ],
                dropDownSelectDate:3,

                remainingQuantity:3, // 잔여 수량
                totalQuantity:12,

                bidCount:1,
                bidPrice:0,

                exchangeRate:2.7,

                suggestCount:1,
                suggestPrice:0,
                sellPrice:0,
                flagModalOnOff: false,

                 alertTitle: '알림',
                alertContent:'준비중 입니다.',
                deliveryType:'리퍼 컨테이너',
                countryList: [
                    {
                        value:'한국',
                        text:'한국'
                    },
                    {
                        value:'미국',
                        text:'미국'
                    },
                    {
                        value:'프랑스',
                        text:'프랑스'
                    },
                    {
                        value:'이탈리아',
                        text:'이탈리아'
                    }
                ],
                selectCountry:null,
                zipcode:'',
                defaultAddress:'',
                detailAddress:''

            }
        },
        watch: {
            bidCount() {
                // 클라단 예외 처리
                if(this.bidCount > this.remainingQuantity) {
                    this.bidCount = this.remainingQuantity
                }
            },
            suggestCount() {
                // 클라단 예외 처리
                if(this.suggestCount > this.remainingQuantity) {
                    this.suggestCount = this.remainingQuantity
                }
            }
        },
        methods: {
            closeMembershipCheckModal() {
                this.checkMembershipModal = false
            },
            moveMembershipPage() {
                this.$router.push('/membership')
            },
            moveRedeemPage() {
                this.$router.push({
                    path: '/redeem',
                    query: {
                        name: this.wineInfo.name,
                        image: this.wineInfo.image,
                        tokenId: this.tokenId,
                        address: this.$route.params.address,
                        user_key: this.wineInfo.owner
                    }
                })
            },
            changePropertyFirstUpper(text) {
                return text.replaceAll('_', ' ').split(' ').map(v => {
                    return v.charAt(0).toUpperCase() + v.slice(1);
                }).join(' ');
            },
            gettingReady() {
                alert('준비중 입니다.')
            },
            toggleCharacter() {
                this.wineCharacteristicOpen = !this.wineCharacteristicOpen
            },
            setModal(title, content) {
                this.modal.title = title
                this.modal.content = content
            },
            setModalWait(showWait) {
                this.modal.showWait = showWait
            },
            async update() {
                const info = await this.market.fetchItemInfoByTokenId(this.tokenId, true);
                const description = `
                   <div class="sc-1xf18x6-0 haVRLx item--description-text">
                        <div class="sc-edvquz-0 fWQpLM">
                            <p>${info.description.replaceAll('\n', "<br/>")}</p>
                        </div>
                    </div>
                `;


                this.$store.dispatch('phpNFT/getWineNFT', {
                  market_key: this.$route.params.address,
                  nft_key: this.$route.params.tokenId
                })

                this.wineInfo = {
                    ...this.wineInfo, ...info,
                    description,
                }

                this.wineInfo.propertyList = {...this.wineInfo.propertyList, ...info.properties}

                let userAddress = await this.market.getUserAddress();
                let self = this

                // this.wineInfo.propertyList.minter = "0x7A255960DbC8e8832000ebE71F550e3f3f469BE9"
                // minter 정보가 있을 때만 매핑 하도록 처리 하였습니다.
                if(this.wineInfo.minter) {
                    self.$store.dispatch('creator/getCreator', { wallet_address: self.wineInfo.minter }).then((data) => {
                        self.wineInfo.propertyList.minter = data.nickname
                    })
                }

                this.isNftSeller = userAddress == this.wineInfo.owner ? true : false;
            },
            async updateActivities() {
                const ms = this.refreshRate;
                if (ms <= 0)
                    return;
                setTimeout(async () => {
                    try {
                        if (this.refreshRate > 0 && !this.suggestModalOpenCheck)
                            await this.doUpdateActivities();
                    } finally {
                        this.updateActivities(this.refreshRate);
                    }
                }, ms);
            },
            async doUpdateActivities() {
                let offers = await this.market.fetchOffers(this.tokenId);
                let userAddress = await this.market.getUserAddress();
                let enableAcceptBnt = userAddress == this.wineInfo.owner ? true : false;
                if (this.wineInfo?.lastOffer === undefined)
                    return;

                this.offerList = [];
                let highest = undefined;
                const entries = [...offers.entries()].sort(([, l], [, r]) => {
                    if (l.price.gt(r.price))
                        return -1;
                    return 0;
                });
                for (const [index, item] of entries) {
                    const coin = this.contract.formatUsdc(item.price);
                    const offer = {
                        buyer: item.buyer
                        , coin
                        , coinOrigin: item.price
                        , won: this.contract.toWon(coin)
                        , key: index
                        , enableAccept: enableAcceptBnt
                    };
                    highest = !highest ? offer : (item.price.lt(highest.coinOrigin) ? highest : offer);
                    this.offerList.push(offer);
                }

                if (highest) {
                    this.$set(this.wineInfo.lastOffer, "buyer", highest.buyer);
                    this.$set(this.wineInfo.lastOffer, "coin", highest.coin);
                    this.$set(this.wineInfo.lastOffer, "won", highest.won)
                } else {
                    this.$set(this.wineInfo.lastOffer, "buyer", 0);
                    this.$set(this.wineInfo.lastOffer, "coin", 0);
                    this.$set(this.wineInfo.lastOffer, "won", 0);
                }
            },
            searchZipCode: function () {
                let self = this
                new window.daum.Postcode({
                    oncomplete: function (data) {
                        self.zipcode = data.zonecode
                        self.defaultAddress = data.roadAddress
                    }
                }).open();
            },
            closeBidModal() {
                this.bidModalOpenCheck = false
            },
            closeSuggestModal() {
                this.suggestModalOpenCheck = false
            },
            closeSellModal() {
                this.sellModelOpenCheck = false
            },
            closeRedeemModal() {
                this.redeemModelOpenCheck = false
            },
            closeCancelModal() {
                this.cancelModelOpenCheck = false
            },
            openSuggestModal() {
                this.suggestPrice = this.wineInfo.price.coin;
                this.suggestModalOpenCheck = true
            },
            openBidModal() {
                this.bidModalOpenCheck = true
            },
            openSellModel() {
                this.sellModelOpenCheck = true;
            },
            openCancelModal() {
                this.cancelModelOpenCheck = true;
            },
            openRedeemModel() {
                this.redeemModelOpenCheck = true;
            },
            minusBidCount() {
                if(this.bidCount > 1) {
                    this.bidCount --
                }
            },
            plusBidCount() {
                if(this.remainingQuantity > this.bidCount) {
                    this.bidCount++
                }
            },
            minusSuggestCount() {
                if(this.suggestCount > 1) {
                    this.suggestCount--
                }
            },
            plusSuggestCount() {
                if(this.remainingQuantity > this.suggestCount) {
                    this.suggestCount++
                }
            },
            submitNftOffer() {
                this.suggestModalOpenCheck = false;
                const titleMsg = "Make Offer";
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'가격제안 요청중입니다'})
                const nPrice  = this.contract.parseUsdc(this.suggestPrice.toString());
                this.withLogin(() => {
                    return this.market.offerNft(this.tokenId, nPrice, (statusMsg,stVal) => {
                        console.log(stVal)
                        this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    });
                }).then(() => {
                    this.flagModalOnOff = true;
                    this.setModal(titleMsg, "Make Offer가 완료되었습니다.");
                    this.$store.commit('loading/done')
                }).catch(e => {
                    console.log(e);
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done');
                    if (e.name === "UserFriendlyError" && e.message) {
                        if (e.message === "LCWC 멤버십을 소유해야 구매 가능합니다.") {
                            this.checkMembershipModal = true;
                            this.flagModalOnOff = false;
                        } else {
                            this.setModal("오류", e.message);
                        }
                    } else {
                        this.setModal("오류", "Make Offer를 실패하였습니다.");
                    }
                });
            },
            submitNftBuy() {
                this.bidModalOpenCheck = false;
                const titleMsg = "바로 구매";
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'계좌 잔고 확인 중입니다!(1/2)'})
                this.withLogin(() => {
                    return this.market.buyNowNft(this.tokenId, (statusMsg,stVal) => {
                        console.log(stVal)
                         this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    });
                }).then(() => {
                     this.flagModalOnOff = true;
                    this.setModal(titleMsg, "바로 구매가 완료되었습니다");
                    this.$store.commit('loading/done')
                }).catch(e => {
                    console.log(e);
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done');
                    if (e.name === "UserFriendlyError" && e.message) {
                        if (e.message === "LCWC 멤버십을 소유해야 구매 가능합니다.") {
                            this.checkMembershipModal = true;
                            this.flagModalOnOff = false;
                        } else {
                            this.setModal("오류", e.message);
                        }
                    } else {
                        this.setModal("오류", "NFT 구매를 실패하였습니다");
                    }
                });
            },
            acceptNftOffer(offer) {
                this.suggestModalOpenCheck = false;
                const titleMsg = "제안가격 수락";
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'계좌 잔고 확인중입니다. (1/2)'})
                this.withLogin(() => {
                    return this.market.acceptNftOffer(this.tokenId, offer.buyer, offer.coinOrigin,  (statusMsg,stVal) => {
                        console.log(stVal)
                        this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    });
                }).then(() => {
                    this.flagModalOnOff = true;
                    this.setModal(titleMsg, "가격제안 수락이 완료되었습니다");
                    this.$store.commit('loading/done')
                }).catch(e => {
                    console.log(e);
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done')
                    this.setModal("오류", "가격 제안 수락을 실패하였습니다.");
                });
            },
            submitNftSell() {
                this.sellModelOpenCheck = false;
                const titleMsg = "판매 등록";
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'제안가격으로 판매 등록 중입니다.(1/2)'})
                //@ToDo:판매가격 오류 및 이상동작을 이곳에서 판단하여 처리함
                this.withLogin(() => {
                    return this.market.sellNft(this.tokenId, this.sellPrice,  (statusMsg,stVal) => {
                       console.log(stVal)
                       this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    });
                }).then(() => {
                    this.flagModalOnOff = true;
                    this.setModal(titleMsg, "제안가격으로 판매 등록 완료되었습니다.");
                    this.$store.commit('loading/done')
                }).catch(e => {
                    console.log(e);
                    this.$store.commit('loading/done')
                    this.flagModalOnOff = true;
                    this.setModal("오류", "판매 등록을 실패하였습니다.");
                });
            },
            submitNftSellCancel() {
                this.cancelModelOpenCheck = false;
                const titleMsg = "판매 취소";
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'판매등록을 취소중입니다.(1/1)'})

                //@ToDo:판매가격 오류 및 이상동작을 이곳에서 판단하여 처리함
                this.withLogin(() => {
                    return this.market.cancelListing(this.tokenId,  (statusMsg,stVal) => {
                        console.log(stVal)
                        this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    })
                }).then(() => {
                    this.flagModalOnOff = true;
                    this.setModal(titleMsg, "판매 취소가 완료되었습니다.");
                    this.$store.commit('loading/done')
                }).catch(e => {
                    console.log(e);
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done')
                    this.setModal("오류", "판매 취소가 실패하였습니다.");
                });
            },
            submitNftRedeem() {
                this.redeemModelOpenCheck = true;
            },
            submitRedeemModal() {
                this.redeemModelOpenCheck = false;
                const titleMsg = "REDEEM 요청";
                this.setModalWait("hide");
                this.$store.commit('loading/init',{title:titleMsg, content:'연결 확인 중입니다.(1/2)'})
                 this.withLogin(() => {
                    return this.market.requestRedeemNft(this.tokenId, false,  (statusMsg,stVal) => {
                        console.log(stVal)
                        this.$store.commit('loading/init',{title:titleMsg, content:statusMsg})
                    })
                }).then(() => {
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done')
                    this.setModal(titleMsg, "REDEEM 요청이 완료되었습니다.");
                }).catch(e => {

                    console.log(e);
                    this.flagModalOnOff = true;
                    this.$store.commit('loading/done')
                    this.setModal("오류", "REDEEM 요청이 실패하였습니다.");
                });
            },
            closeModal() {
                // 예외 분기 처리 필요 : ex. 민팅 실패 시, TIME OUT 시
                // ---
                // 모달 닫기
                this.flagModalOnOff = false;
               // location.reload();
                this.update();
                // 민팅 정상 완료 시 이동
                // if (this.createdNFT) {
                //     this.$router.push(`/market/detail/${this.createdNFT}`);
                // }
            },
            withLogin(routine) {
                return this.contract.getUserAddress().then(address => {
                    if (!address)
                        throw Error("지갑을 연결해주세요.");
                    return routine(address);
                });
            },

          getSubCategoryInfo(mainCategoryNo){
            let subCategory = null;
            if(this.wineNFT){
              const propertyList = this.wineNFT.wine_nft_properties;

              propertyList.forEach((property) => {
                if(property.main_category_no === mainCategoryNo){
                  subCategory = {
                    name: property.filter_sub_categories.filter_sub_category_name,
                    categoryNo: property.sub_category_no
                  };
                }
              })

              if(subCategory == null){
                switch (mainCategoryNo){
                  case 1:
                    subCategory = {name: this.wineInfo.propertyList.type};
                    break;
                  case 2:
                    subCategory = {name: this.wineInfo.propertyList.country};
                    break;
                  case 3:
                    subCategory = {name: this.wineInfo.propertyList.format};
                    break;
                  case 4:
                    subCategory = {name: this.wineInfo.propertyList.grade};
                    break;
                  case 16:
                    subCategory = {name: this.wineInfo.propertyList.winery};
                    break;
                  case 17:
                    subCategory = {name: this.wineInfo.propertyList["case * bottle"]};
                    break;
                  case 18:
                    subCategory = {name: this.wineInfo.propertyList.grape};
                    break;
                  case 19:
                    subCategory = {name: this.wineInfo.propertyList.region};
                    break;
                  case 20:
                    subCategory = {name: this.wineInfo.propertyList.subregion_1};
                    break;
                  case 21:
                    subCategory = {name: this.wineInfo.propertyList.subregion_2};
                    break;
                  case 22:
                    subCategory = {name: this.wineInfo.propertyList.subregion_3};
                    break;
                  case 23:
                    subCategory = {name: this.wineInfo.propertyList.vineyard};
                    break;
                  case 24:
                    subCategory = {name: this.wineInfo.propertyList.vintage};
                    break;
                  case 25:
                    subCategory = {name: this.wineInfo.propertyList.wmv};
                    break;
                }
              }

              return subCategory;
            }


          },
        },
        computed: {
            suggestPriceToWon(){
                return this.contract.toWon(this.suggestPrice);
            },
            sellPriceToWon(){
                return this.contract.toWon(this.sellPrice);
            },
            sellCoinPrice(){
                return this.wineInfo.price.coin * this.bidCount;
            },
            sellWonPrice(){
                return this.wineInfo.price.won * this.bidCount;
            },
          ...mapState('phpNFT', ['wineNFT'])
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/scss/nft-detail.scss";
    @import "@/assets/scss/redeem.scss";
</style>
